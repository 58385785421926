@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .collapse:not(.show) {
        display: unset;
    }
    .collapse {
        display: grid !important;
    }
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Regular.ttf") format("opentype");
}

body {
    margin: 0;
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
